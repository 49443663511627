import { environment } from '../../../environments/environment';

export class Routes {
    public static LOGOUT = '/auth/logout';
    public static ACCESS = '/auth/access';
    public static AFTER_LOGIN_REDIRECT = environment.afterLoginRedirect;
}

export function handleRedirect(redirect?: string): void {
    if (redirect) {
        window.location.href = redirect;
    } else {
        window.location.href = Routes.AFTER_LOGIN_REDIRECT;
    }
}

export function buildRedirectUrl(redirect: string, action?: string, accessToken?: string): string {
    const parsedUrl = new URL(redirect);
    const params = new URLSearchParams(parsedUrl.search);

    if (action) {
        params.set('action', action);
    }

    if (accessToken) {
        params.set('accessToken', accessToken);
    }

    parsedUrl.search = params.toString();

    return parsedUrl.toString();
}
